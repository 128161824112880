<template>
	<div class="banner">
		<div class="indicator">
			<div class="number">{{banner.current<10?'0'+(banner.current+1):banner.current+1}}</div>
			<div class="progress">
				<div class="val" :style="`right: ${100-banner.progress}%`"></div>
			</div>
			<div class="number">{{banner.size<10?`0${banner.size}`:banner.size}}</div>
		</div>
		
		<!-- <div class="arrow">
			<i @click="setActive('prev')" :class="`${banner.current == 0?'disable':''} el-icon-back`"></i>
			<i @click="setActive('next')" :class="`${banner.current == banner.size-1?'disable':''} el-icon-right`"></i>
		</div> -->
		<el-carousel ref="banner" @change="onBannerChange" :loop="true" :autoplay="false" :interval="2000" arrow="never" >
			<el-carousel-item v-for="val in banner.item" >
				<img class="bannerPic" :src="val.img">
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
export default {
	name: 'BannerPc',
	props:{
		data:{
			type: Array,
			default: []
		}
	},
	data(){
		return {
			banner:{
				progress: 0,
				current: 0,
				size: 4,
				item: [1,2,3,4]
			}
		}
	},
	watch:{
		data(res){
			this.banner.item = res;
			this.banner.size = res.length;
			this.banner.progress = (100/res.length).toFixed(0);
		}
	},
	created(){
		
	},
	methods:{
		onBannerChange(res){  //banner翻页
			this.banner.progress = (res+1)/(this.banner.size)*100
			this.banner.current = res;
		},
		setActive(type){
			if(type == 'prev'){
				this.$refs.banner.prev()
			}else{
				this.$refs.banner.next()
			}
		}
	}
};
</script>

<style lang="less" scoped>
.banner{
	height: 910px;
	background: #ccc;
	position: relative;
	/deep/.el-carousel{
		height: 100%;
		.el-carousel__container{
			height: 100%;
		}
		.el-carousel__indicators{
			.el-carousel__indicator{
				padding: 15px 6px;
				.el-carousel__button{
					width: 10px;
					height: 10px;
					border-radius: 50%;
				}
			}
		}
	}
	.bannerPic{
		width: 100%;
		object-fit: cover;
	}
	.indicator{
		position: absolute;
		z-index: 9;
		bottom: 47px;
		left: 80px;
		display: flex;
		align-items: center;
		.number{
			color: #fff;
			font-size: 16px;
			font-family: GillSans;
			font-weight: 600;
		}
		.progress{
			width: 264px;
			height: 2px;
			background: #ABABAB;
			border-radius: 1px;
			margin: 0 30px;
			position: relative;
			.val{
				position: absolute;
				z-index: 10;
				top: -1px;
				bottom: -1px;
				border-radius: 2px;
				background: #fff;
				left: 0;
				transition:  all .3s;
			}
		}
	}
	.arrow{
		position: absolute;
		z-index: 9;
		right: 80px;
		bottom: 34px;
		&>i{
			color: #fff;
			font-size: 32px;
			font-weight: bold;
			margin-left: 45px;
			cursor: pointer;
		}
		&>i.disable{
			color: #ABABAB;
			cursor: not-allowed;
		}
	}
}
</style>
